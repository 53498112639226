import type { SpeciesListViewModel } from '@/project/presentation/view-models/SpeciesList.viewmodel'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class WhichSpeciesArePresentInThisProjectPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<SpeciesListViewModel> {
    const project = this.projectRepository.getProject()
    return ViewModelContainer.WithContent({
      species: project.species
        .sort((a, b) => b.repartition - a.repartition)
        .map((specie) => ({
          englishName: specie.name,
          scientificName: specie.scientificName,
          breakdown: specie.repartition ? specie.repartition.toString() + '%' : '',
        })),
    })
  }
}
