import type { PhotosViewModel } from '@/project/presentation/view-models/Photos.viewmodel'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type BackofficeGateway from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { FisProjectId } from '@/shared/domain/Ids'
import AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'
import { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'

export default class CanISeeSomePhotosOfMyProjectPresenter {
  public constructor(
    private readonly backofficeGateway: BackofficeGateway,
    private readonly authenticationService: AuthenticationService,
  ) {}

  public async handle(projectId: FisProjectId): Promise<ViewModelContainer<PhotosViewModel>> {
    const accessToken = await this.authenticationService.getAccessToken()

    const response = await this.backofficeGateway.getMedias(accessToken.token, projectId)
    if (response instanceof NoResult)
      return ViewModelContainer.WithError(new NoPhotoAvailableError())

    return ViewModelContainer.WithContent({
      urls: response.data.map((d) => ({
        thumbnail: d.thumbnailUrl,
        hd: d.url,
      })),
    })
  }
}

class NoPhotoAvailableError extends Error {
  public constructor() {
    super('projectPage.photo.error.noPhotoAvailable')
  }
}
