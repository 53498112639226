import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import {
  type MonitoringViewModel,
  TimelineStep,
} from '@/project/presentation/view-models/Monitoring.viewmodel'
import MonitoringCalendar, { type Monitoring } from '@/project/domain/entities/MonitoringCalendar'
export default class WhatIsMyProjectTimelinePresenter {
  public constructor(private readonly detailedProjectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<MonitoringViewModel> {
    const project = this.detailedProjectRepository.getProject()

    if (project.isMonitoringFilled()) {
      const monitoringCalendar = project.getMonitoringCalendar()
      return ViewModelContainer.WithContent({
        timelineSteps: [
          TimelineStep.AsGeneralStatus(
            'projectPage.monitoring.step.readyToStart',
            monitoringCalendar.plantingStartDate,
          ),
          TimelineStep.AsMilestone(
            'projectPage.monitoring.step.plantation.startDate',
            monitoringCalendar.plantingStartDate,
          ),
          TimelineStep.AsGeneralStatus(
            'projectPage.monitoring.step.plantation.inProgress',
            monitoringCalendar.plantingStartDate,
          ),
          TimelineStep.AsGeneralStatus(
            'projectPage.monitoring.step.plantation.complete',
            monitoringCalendar.plantingEndDate,
          ),
          TimelineStep.AsMilestone(
            'projectPage.monitoring.step.plantation.endDate',
            monitoringCalendar.plantingEndDate,
          ),
          ...this.getMonitoringSteps(monitoringCalendar),
        ],
      })
    }

    return ViewModelContainer.WithError(new NoMonitoringAvailable())
  }

  private getMonitoringSteps(monitoringCalendar: MonitoringCalendar) {
    const fieldMonitoring = monitoringCalendar.getFieldMonitoring()
    const remoteMonitoring = monitoringCalendar.getRemoteMonitoring()

    const fieldMonitoringStep = this.getMonitoringStepFrom(fieldMonitoring)
    const remoteMonitoringStep = this.getMonitoringStepFrom(remoteMonitoring)

    if (this.isFieldBeforeRemoteMonitoring(fieldMonitoring, remoteMonitoring)) {
      const monitoringStatus = TimelineStep.AsGeneralStatus(
        'projectPage.monitoring.step.monitoring.title',
        fieldMonitoring.date,
      )
      return [monitoringStatus, fieldMonitoringStep, remoteMonitoringStep]
    }

    const monitoringStatus = TimelineStep.AsGeneralStatus(
      'projectPage.monitoring.step.monitoring.title',
      remoteMonitoring.date,
    )
    return [monitoringStatus, remoteMonitoringStep, fieldMonitoringStep]
  }

  private isFieldBeforeRemoteMonitoring(fieldMonitoring: Monitoring, remoteMonitoring: Monitoring) {
    return fieldMonitoring.date < remoteMonitoring.date
  }

  private getMonitoringStepFrom(monitoring: Monitoring): TimelineStep {
    const commonTranslationPath = `projectPage.monitoring.step.monitoring`

    return TimelineStep.AsMilestone(
      `${commonTranslationPath}.${monitoring.type}.${monitoring.occurrence}`,
      monitoring.date,
    )
  }
}

class NoMonitoringAvailable extends Error {
  public constructor() {
    super('projectPage.monitoring.error.noMonitoringAvailable')
  }
}
