export const commonChartConfiguration = {
  responsive: true,
  animation: {
    delay: (context: any): any => {
      return (context.dataIndex + context.datasetIndex) * 50
    },
  },
  scales: (
    ordinateUnit: string,
    minMax: [number, number] | undefined = undefined,
    stacked: boolean = false,
    abscissaUnit?: string,
  ): any => {
    const scales = {
      y: {
        stacked: stacked,
        title: {
          display: true,
          text: ordinateUnit,
        },
      },
      x: {
        stacked: stacked,
        ticks: {
          autoSkip: false,
        },
        title: {
          display: true,
          text: abscissaUnit ?? 'Years',
        },
        grid: {
          display: false,
        },
      },
    }

    if (minMax) {
      return Object.assign(scales, {
        y: { ...scales.y, min: minMax[0], max: minMax[1] },
      })
    } else {
      return scales
    }
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}
