<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
  <div class="wrapper">
    <Suspense>
      <RouterView />
      <template #fallback>{{ t('shared.loading') }}</template>
    </Suspense>
  </div>
</template>

<style scoped>
@media screen and (min-width: 1400px) {
  .wrapper {
    margin: 0 auto;
    @mixin flex;
  }
}
</style>
