import DateOfNextRemoteMonitoring from '@/project/domain/value-objects/DateOfNextRemoteMonitoring'
import DateOfNextFieldMonitoring from '@/project/domain/value-objects/DateOfNextFieldMonitoring'
import type { ProjectType } from '@/project/domain/entities/DetailedProject'

export type Monitoring = {
  occurrence: 'next' | 'latest'
  type: 'field' | 'remote'
  date: Date
}

export default class MonitoringCalendar {
  public constructor(
    public readonly plantingStartDate: Date,
    public readonly plantingEndDate: Date,
    private readonly dateOfNextRemoteMonitoring: DateOfNextRemoteMonitoring,
    private readonly dateOfNextFieldMonitoring: DateOfNextFieldMonitoring,
  ) {}

  public static hydrate(
    rawMonitoring: MonitoringCalendar,
    projectType: ProjectType,
  ): MonitoringCalendar {
    return new MonitoringCalendar(
      new Date(rawMonitoring.plantingStartDate),
      new Date(rawMonitoring.plantingEndDate),
      new DateOfNextRemoteMonitoring(
        new Date(rawMonitoring.plantingStartDate),
        new Date(rawMonitoring.plantingEndDate),
        projectType,
      ),
      new DateOfNextFieldMonitoring(new Date(rawMonitoring.plantingEndDate), projectType),
    )
  }

  public getRemoteMonitoring(): Monitoring {
    const date = this.dateOfNextRemoteMonitoring.value
    return {
      occurrence: this.hasMonitoringHappened(date) ? 'latest' : 'next',
      type: 'remote',
      date,
    }
  }

  public getFieldMonitoring(): Monitoring {
    const date = this.dateOfNextFieldMonitoring.value
    return {
      occurrence: this.hasMonitoringHappened(date) ? 'latest' : 'next',
      type: 'field',
      date,
    }
  }

  private hasMonitoringHappened(monitoring: Date): boolean {
    return monitoring < new Date(Date.now())
  }
}
