export const descriptions = {
  climate:
    'The impact of the project on climate is calculated in tons of CO2 equivalent thanks to our carbon projection tool, which follows the methodologies of CDM (Clean Development Mechanism) and VCS (Verified Carbon Standard), and has been validated by Bureau Veritas. This unit is used to express the additional sequestration of greenhouse gases in the ecosystem, that is enabled by the project activities. It comprises the sequestration in above- and below-ground biomass, soil organic carbon, and, when relevant, the residual sequestration in wood products and the emissions resulting from the displacement of pre-project activities (leakage) and burning of biomass.',
  biodiversity:
    'The biodiversity projection tool evaluates how the design of the project, depending on its context, should restore the biological and structural properties of a reference ecosystem, considered optimal in terms of biodiversity.' +
    "The project's impact on biodiversity is calculated using a restoration score ranging from 0 (no biodiversity) to 1 (equivalent to the reference ecosystem)." +
    'This score is converted in km²eqRE, which corresponds of a unit of reference ecosystem surface that is restored through the project.  \n' +
    'This takes into account the species planted or supported, the structural and biological diversity of the stand, the habitats and resources maintained or created for biodiversity, and the disturbance generated by the work.',
  typology:
    'The primary typology refers to the predominant planting or land-use method used in the project.' +
    'The secondary typology refers to the other planting or land use methods employed in the project, in a complementary manner.',
  speciesDestination:
    'Main species destination refers to the human utilization of the species present in the project, such as for wood, fruit, medicine, etc.',
}

abstract class ValueObject {}

export class Description extends ValueObject {
  public readonly value: string

  public constructor(rawDescription: string) {
    super()
    this.value = this.parseDescription(rawDescription)
  }

  private parseDescription(rawDescription: string | null): string {
    const defaultDescription = 'No description yet.'

    if (rawDescription) {
      const frEnSeparator = /[\n\r]-+[\n\r]/
      const result = rawDescription.split(frEnSeparator).map((s) => s.trim())
      if (result.length === 1) return result[0]
      if (result.length === 2) return result[1]
    }

    return defaultDescription
  }
}
