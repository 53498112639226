import type {
  BiodiversitySimulatorGateway,
  BiodiversitySimulationApiResults,
} from '@/shared/infrastructure/gateways/BiodiversitySimulator.gateway'
import { FetchGateway, NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { FisProjectId } from '@/shared/domain/Ids'
import type AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'
import type { AccessToken } from '@/authentication/infrastructure/services/Authentication.service'

export class BiodiversitySimulatorHttpGateway
  extends FetchGateway
  implements BiodiversitySimulatorGateway
{
  public baseUrl: string

  public constructor(
    _baseUrl: string,
    private readonly authenticationService: AuthenticationService,
  ) {
    super()
    this.baseUrl = _baseUrl
  }

  public async getProjectsResults(
    projectIds: FisProjectId[],
  ): Promise<BiodiversitySimulationApiResults[] | NoResult> {
    let accessToken: AccessToken
    try {
      accessToken = await this.authenticationService.getAccessToken()
    } catch (e: unknown) {
      console.warn((e as Error).message)
      return []
    }
    return this.fetch<BiodiversitySimulationApiResults[]>(
      `/biodiversity/projects/results?ids=${projectIds.join(',')}`,
      { method: 'GET', accessToken: accessToken.token },
    )
  }
}
