import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import type { DownloadExcelViewModel } from '@/project/presentation/view-models/DownloadExcelViewModel'
import * as ExcelJS from 'exceljs'

export default class CanIHaveTheProjectInExcelFormatPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public async handle(): Promise<ViewModelContainer<DownloadExcelViewModel>> {
    const project = this.projectRepository.getProject()

    const fileName =
      project.location.city + ' ' + project.location.country + ' ' + project.plantingSeason

    const list = [
      {
        label: 'location',
        value:
          project.location.city + ' ' + project.location.country + ' ' + project.location.continent,
      },
      {
        label: 'season',
        value: project.plantingSeason,
      },
      {
        label: 'implementor',
        value: project.implementorName ? project.implementorName.toString() : 'None',
      },
      {
        label: 'coordinates',
        value: project.location.coordinates.latitude + ',' + project.location.coordinates.longitude,
      },
      {
        label: 'typologies',
        value: project.typologies.main + ' ' + project.typologies.secondaries.join(' '),
      },
      {
        label: 'area',
        value: project.getFinancedArea().toString(),
      },
      {
        label: 'number of financed trees',
        value: project.getFinancedTrees().toString(),
      },
      {
        label: 'sequestered carbon at 30 years',
        value: project.getFinancedSequesteredCarbon().toString(),
      },
      {
        label: 'biodiversity impact at 30 years',
        value: project.getFinancedBiodiversityImpact().toString(),
      },
      {
        label: 'number of species',
        value: project.species.length.toString(),
      },
      {
        label: 'tree density',
        value: project.getFinancedSequesteredCarbon().toString(),
      },
      {
        label: 'species',
        value: project.species.map((s) => s.name).join(', '),
      },
      {
        label: 'planting start date',
        value: this.formatDateAsMonthAndYear(project.getMonitoringCalendar().plantingStartDate),
      },
      {
        label: 'planting end date',
        value: this.formatDateAsMonthAndYear(project.getMonitoringCalendar().plantingEndDate),
      },
      {
        label: `${
          project.getMonitoringCalendar().getFieldMonitoring().occurrence
        } field monitoring`,
        value: this.formatDateAsMonthAndYear(
          project.getMonitoringCalendar().getFieldMonitoring().date,
        ),
      },
      {
        label: `${
          project.getMonitoringCalendar().getFieldMonitoring().occurrence
        } remote monitoring`,
        value: this.formatDateAsMonthAndYear(
          project.getMonitoringCalendar().getRemoteMonitoring().date,
        ),
      },
    ]

    const url = await this.generateExcel(list)

    return ViewModelContainer.WithContent({
      name: fileName,
      url,
    })
  }

  private async generateExcel(data: { label: string; value: string }[]): Promise<string> {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Feuille1')

    data.forEach((item) => {
      worksheet.addRow([item.label, item.value])
    })

    worksheet.getColumn(1).width = 30

    const blob = await workbook.xlsx.writeBuffer()
    return URL.createObjectURL(new Blob([blob]))
  }

  private formatDateAsMonthAndYear(date: Date): string {
    const month = '0' + (date.getMonth() + 1)
    return month.slice(-2) + '/' + date.getFullYear()
  }
}
