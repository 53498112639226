export class TimelineStep {
  private constructor(
    public readonly label: string,
    private readonly date: Date,
    private readonly isDateDisplayed: boolean,
  ) {}

  public static AsGeneralStatus(label: string, date: Date): TimelineStep {
    return new TimelineStep(label, date, false)
  }

  public static AsMilestone(label: string, date: Date): TimelineStep {
    return new TimelineStep(label, date, true)
  }

  public isGeneralStatus(): boolean {
    return !this.isDateDisplayed
  }

  public isMilestone(): boolean {
    return this.isDateDisplayed
  }

  public displayDate(): string {
    return (this.date.getMonth() + 1).toString().padStart(2, '0') + '/' + this.date.getFullYear()
  }

  public isInFuture(): boolean {
    return this.date > new Date()
  }
}

export type MonitoringViewModel = {
  timelineSteps: TimelineStep[]
}
