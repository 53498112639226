import { createRouter, createWebHistory } from 'vue-router'
import { container } from '@/shared/container'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.name === 'project') {
      return { top: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: (to) => container.authenticationService.handleBlankPathRedirection(to),
    },
    {
      path: '/:contributor_id',
      name: 'home',
      component: () => import('@/contributor/presentation/pages/ContributorPage.vue'),
      props: {
        authenticationService: container.authenticationService,
        httpContributorRepository: container.contributorRepository,
        whoAmIPresenter: container.whoAmIPresenter,
        whereICanSeeMyAnnualReportPresenter: container.whereICanSeeMyAnnualReportPresenter,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/authentication/presentation/pages/LoginPage.vue'),
      props: {
        storageService: container.storageService,
        authenticationService: container.authenticationService,
      },
    },
    {
      path: '/:contributor_id/project/:id/:idBiome',
      name: 'project',
      component: () => import('@/project/presentation/pages/ProjectPage.vue'),
      props: (route) => ({
        id: parseInt(route.params.id as string, 10),
        biomeId: route.params.idBiome,
        authenticationService: container.authenticationService,
        httpContributorRepository: container.contributorRepository,
        httpProjectRepository: container.projectRepository,
        whatIsThisProjectSummaryPresenter: container.whatIsThisProjectSummaryPresenter,
        howManyTreesInThisProjectPresenter: container.howManyTreesInThisProjectPresenter,
        whatAreaDoesThisProjectCoverPresenter: container.whatAreaDoesThisProjectCoverPresenter,
        whatIsThisProjectTypologiesPresenter: container.whatIsThisProjectTypologiesPresenter,
        whatIsMyProjectStatusPresenter: container.whatIsMyProjectStatusPresenter,
        whereIsLocatedMyProjectPresenter: container.whereIsLocatedMyProjectPresenter,
        whichSpeciesArePresentInThisProjectPresenter:
          container.whichSpeciesArePresentInThisProjectPresenter,
        whatIsSpeciesDiversityInThisProjectPresenter:
          container.whatIsSpeciesDiversityInThisProjectPresenter,
        whatWillTheUsesOfWoodBeOnceCutPresenter: container.whatWillTheUsesOfWoodBeOnceCutPresenter,
        whatIsMyProjectTimelinePresenter: container.whatIsMyProjectTimelinePresenter,
        canISeeSomePhotosOfMyProjectPresenter: container.canISeeSomePhotosOfMyProjectPresenter,
        howManyCarbonISequesteredOverThirtyYearsPresenter:
          container.howManyCarbonISequesteredOverThirtyYearsPresenter,
        whatIsMyBiodiversityImpactOverThirtyYearsPresenter:
          container.whatIsMyBiodiversityImpactOverThirtyYearsPresenter,
        canIHaveTheProjectInExcelFormatPresenter:
          container.canIHaveTheProjectInExcelFormatPresenter,
        canISeeMyRemoteSensingPresenter: container.canISeeMyRemoteSensingPresenter,
        canISeeMyRemoteSensingSpectralSpeciesPresenter:
          container.canISeeMyRemoteSensingSpectralSpeciesPresenter,
      }),
    },
  ],
})

router.beforeEach(async (to) => {
  if (to.name === 'login') {
    container.authenticationService.cleanContributorData()
  } else {
    await container.authenticationService.handleLogOutRedirection(to)
  }
})

export default router
