import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { SpeciesDiversityViewModel } from '@/project/presentation/view-models/SpeciesDiversity.viewmodel'
import { Unit } from '@/shared/domain/value-objects/Units'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import type { Species } from '@/project/domain/entities/DetailedProject'
import type { ChartMeasure } from '@/shared/presentation/presenters/ChartMeasure'

export default class WhatIsSpeciesDiversityInThisProjectPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<SpeciesDiversityViewModel> {
    const project = this.projectRepository.getProject()
    const sortedSpeciesByRepartition = this.computeDisplayChartWithTheSixMostRepresentedSpecies(
      project.species,
    )

    return ViewModelContainer.WithContent({
      speciesByRepartition: sortedSpeciesByRepartition,
      numberOfSpecies: {
        unit: Unit.SPECIES,
        value: project.species.length,
      },
      treeDensity: {
        unit: Unit.TREES_PER_HECTARE,
        value: project.getTreeDensity(),
      },
    })
  }

  private computeDisplayChartWithTheSixMostRepresentedSpecies(species: Species): ChartMeasure[] {
    const sortedSpeciesByRepartition = species.sort((a, b) => b.repartition - a.repartition)

    if (species.length <= 6) {
      return sortedSpeciesByRepartition.map((specie) => ({
        unit: Unit.SPECIES,
        value: specie.repartition,
        label: specie.name,
      }))
    } else {
      const [theFifthMostCommon, theOther] = [
        sortedSpeciesByRepartition.slice(0, 5),
        sortedSpeciesByRepartition.slice(5, sortedSpeciesByRepartition.length),
      ]

      const repartitionOfTheFifthMostCommon: ChartMeasure[] = theFifthMostCommon.map((specie) => ({
        unit: Unit.SPECIES,
        value: specie.repartition,
        label: specie.name,
      }))

      const countRepartitionOfTheOtherSpecies = theOther.map((specie) => specie.repartition).sum()
      const remainingReparationOfTheOtherSpecies: ChartMeasure = {
        unit: Unit.SPECIES,
        value: countRepartitionOfTheOtherSpecies,
        label: 'Others',
      }

      return repartitionOfTheFifthMostCommon.concat(remainingReparationOfTheOtherSpecies)
    }
  }
}
