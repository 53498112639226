import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { IndexesByYearViewModel } from '@/project/presentation/view-models/NdviByYear.viewmodel'
import constants from 'design-system/src/styles/constants'
import { commonChartConfiguration } from '@/shared/presentation/configurations/CommonChart.configuration'
import type { IndexForAYear } from '@/project/domain/entities/RemoteSensingIndexes'

export default class CanISeeMyRemoteSensingIndexesPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<IndexesByYearViewModel> {
    const project = this.projectRepository.getProject()

    if (project.remoteSensing === null) {
      return ViewModelContainer.WithError(new NoIndexesAvailable())
    }

    const validatedNdvisForAYear = project.remoteSensing.getValidatedNdvisForAYear()

    if (validatedNdvisForAYear.length === 0) {
      return ViewModelContainer.WithError(new NoIndexesAvailable())
    }

    const validatedNdmisForAYear = project.remoteSensing.getValidatedNdmisForAYear()
    const years = validatedNdvisForAYear.map((vm) => vm.year)

    return ViewModelContainer.WithContent(<IndexesByYearViewModel>{
      ndvis: this.getIndexesViewModel(validatedNdvisForAYear),
      ndmis: this.getIndexesViewModel(validatedNdmisForAYear),
      years: years,
      chartDatasetNdmi: this.getChartDataset(validatedNdmisForAYear),
      chartDatasetNdvi: this.getChartDataset(validatedNdvisForAYear),
      chartOptionsNdmi: this.getChartOption(project.remoteSensing.ndmiRangeValue),
      chartOptionsNdvi: this.getChartOption(project.remoteSensing.ndviRangeValue),
      firstYear: this.getFirstYear(years),
    })
  }

  private getFirstYear(years: number[]) {
    return years.reduce((a, b) => (a < b ? a : b))
  }

  private getIndexesViewModel(indexForAYears: IndexForAYear[]) {
    return indexForAYears.map((index) => {
      return {
        tifUrl: index.url,
        maximum: index.maximum,
        mean: index.mean,
        minimum: index.minimum,
        year: index.year,
      }
    })
  }

  private getChartDataset(indexForAYears: IndexForAYear[]) {
    return {
      labels: indexForAYears.map((index) => index.year),
      datasets: [
        {
          type: 'line',
          data: indexForAYears.map((index) => index.maximum),
          borderColor: constants.colors.greenLight,
        },
        {
          type: 'line',
          data: indexForAYears.map((index) => index.mean),
          borderColor: constants.colors.greenLighter,
        },
        {
          type: 'line',
          data: indexForAYears.map((index) => index.minimum),
          borderColor: constants.colors.red,
        },
      ],
    }
  }

  private getChartOption(range: [number, number]) {
    return {
      responsive: commonChartConfiguration.responsive,
      animation: commonChartConfiguration.animation,
      scales: commonChartConfiguration.scales('', range, false, ''),
      plugins: commonChartConfiguration.plugins,
    }
  }
}

class NoIndexesAvailable extends Error {
  public constructor() {
    super('No data')
  }
}
