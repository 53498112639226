import type { AccessToken } from '@/authentication/infrastructure/services/Authentication.service'
import AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'
import StorageService, { NoItemFound } from '@/shared/infrastructure/services/Storage.service'
import type BackofficeGateway from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { FisMediaList, FisProject } from '@/shared/infrastructure/gateways/Backoffice.gateway'
import { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type {
  BiomeProjectId,
  CarbonSimulatorProjectId,
  FisClientId,
  FisProjectId,
} from '@/shared/domain/Ids'
import type CarbonSimulatorGateway from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import type { CarbonSimulatorScenarioApiResponse } from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import Contributor from '@/contributor/domain/entities/Contributor'
import type ContributorRepository from '@/contributor/domain/repositories/Contributor.repository'
import { Clock, type Milliseconds } from '@/shared/domain/Clock'
import type {
  BiodiversitySimulationApiResults,
  BiodiversitySimulatorGateway,
} from '@/shared/infrastructure/gateways/BiodiversitySimulator.gateway'
import pLimit from 'p-limit'
import { ContributorFactory } from '@/contributor/infrastructure/repositories/Contributor.factory'
import { ProjectStatut } from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import { useContributorStore } from '@/contributor/domain/stores/Contributor.store'

export default class ContributorHttpRepository implements ContributorRepository {
  public constructor(
    private readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
    private readonly backofficeGateway: BackofficeGateway,
    private readonly carbonSimulatorGateway: CarbonSimulatorGateway,
    private readonly biodiversitySimulatorGateway: BiodiversitySimulatorGateway,
  ) {}

  public async loadContributor(contributorId: FisClientId): Promise<void> {
    const contributor = await this.refreshContributor(contributorId)
    const store = useContributorStore()
    store.initialize(contributor.summarizedProjects)
  }

  private async refreshContributor(contributorId: FisClientId) {
    const accessToken = await this.authenticationService.getAccessToken()

    const client = await this.backofficeGateway.getClient(accessToken.token, contributorId)
    if (client instanceof Error) throw new Error('Could not get client from email')
    this.storageService.set('client', client, this.delayBeforeAccessTokenExpires(accessToken))

    const clientMedias = await this.backofficeGateway.getMediaClients(accessToken.token, client.id)
    if (clientMedias instanceof Error) throw new Error('Could not get client medias')

    const biomeProjectsIds = client.attributions.map((attr) => attr.idProject).deduplicate()
    const projects = await this.getContributorProjects(accessToken.token, biomeProjectsIds)

    const fisProjectsIds = projects.map((project) => project.id)

    const projectsMedias = await this.getContributorProjectsMedias(
      accessToken.token,
      fisProjectsIds,
    )

    const carbonSimulations = await this.getAllCarbonSimulationsForContributor(biomeProjectsIds)
    if (carbonSimulations instanceof Error) throw new Error('Could not get carbon simulations')

    const biodiversitySimulations =
      await this.getAllBiodiversitySimulationsForContributor(fisProjectsIds)

    const contributor = ContributorFactory.toContributor(
      client,
      clientMedias,
      projects,
      projectsMedias,
      carbonSimulations,
      biodiversitySimulations,
    )
    this.storageService.set(
      'contributor',
      contributor,
      this.delayBeforeAccessTokenExpires(accessToken),
    )

    return contributor
  }

  public getContributor(): Contributor {
    const rawContributor = this.storageService.get<Contributor>('contributor')
    if (rawContributor instanceof NoItemFound) throw rawContributor
    return Contributor.hydrate(rawContributor)
  }

  private delayBeforeAccessTokenExpires(accessToken: AccessToken): Milliseconds {
    return (Clock.fromIsoToTimestamp(accessToken.expires) - Clock.nowTimestamp()) as Milliseconds
  }

  private async getContributorProjects(
    accessToken: string,
    projectIds: BiomeProjectId[],
  ): Promise<FisProject[]> {
    const limit = pLimit(3)

    const getProject = (projectId: BiomeProjectId) => async () => {
      const project = await this.backofficeGateway.getProject(accessToken, projectId)
      if (!(project instanceof Error)) {
        // On n'essaye de pas se baser sur la lat/lng qui est dans le projet, qui peut être fausse met plutôt sur la première lat/lng
        // qui vient de l'endpoint `/locations` qui est plus précise
        const location = await this.backofficeGateway.getLocations(accessToken, project.id)
        if (!(location instanceof Error) && location.data.length > 0) {
          return {
            ...project,
            centroidLatitude: location.data[0].centroidLat,
            centroidLongitude: location.data[0].centroidLng,
          }
        }
        return project
      }
      console.error('Fail to get project with id ' + projectId)
    }

    const input = projectIds.map((projectId) => limit(getProject(projectId)))

    return (await Promise.all(input)).filter((val) => val !== undefined) as FisProject[]
  }

  private async getContributorProjectsMedias(
    accessToken: string,
    projectIds: FisProjectId[],
  ): Promise<FisProjectMediaList[]> {
    const limit = pLimit(3)

    const getProjectMedias = (projectId: FisProjectId) => async () => {
      const project = await this.backofficeGateway.getMedias(accessToken, projectId)
      if (!(project instanceof Error)) {
        return {
          ...project,
          projectFisId: projectId,
        }
      }
      console.error('Fail to get project medias with id ' + projectId)
    }

    const input = projectIds.map((projectId) => limit(getProjectMedias(projectId)))

    return (await Promise.all(input)).filter((val) => val !== undefined) as FisProjectMediaList[]
  }

  private async getAllCarbonSimulationsForContributor(
    projectIds: BiomeProjectId[],
  ): Promise<CarbonSimulationForProject[] | Error> {
    const simulatedProjects = await this.carbonSimulatorGateway.getValidatedProjects(projectIds)
    if (simulatedProjects instanceof Error) {
      return new Error('No projects found in carbon simulator')
    }

    const filteredSimulatedProjects = simulatedProjects.results.filter((p) => {
      const UNARCHIVED = p.archived ? p.archived.status === ProjectStatut.UNARCHIVED : true
      return projectIds.includes(p.id) && UNARCHIVED
    })
    const limit = pLimit(3)

    const getScenario =
      (biomeId: BiomeProjectId, projectId: CarbonSimulatorProjectId) =>
      async (): Promise<CarbonSimulationForProject | undefined> => {
        const scenario = await this.carbonSimulatorGateway.getScenario(projectId)
        if (scenario instanceof NoResult) {
          console.error('No scenario found for id ' + projectId)
        } else if (!scenario.results) {
          console.error('No scenario results found for id ' + projectId)
        } else {
          return { ...scenario, projectBiomeId: biomeId }
        }
      }

    const input = filteredSimulatedProjects.map((project) =>
      limit(getScenario(project.id, project._id)),
    )

    const scenarios = await Promise.all(input)
    const scenariosWithoutFailures = scenarios.filter((s) => s !== undefined)

    return scenariosWithoutFailures as CarbonSimulationForProject[]
  }

  private async getAllBiodiversitySimulationsForContributor(
    projectIds: FisProjectId[],
  ): Promise<BiodiversitySimulationApiResults[]> {
    const projectsResults = await this.biodiversitySimulatorGateway.getProjectsResults(projectIds)
    return projectsResults instanceof NoResult ? [] : projectsResults
  }
}

export class NoContributorFoundForAuthenticatedUser extends Error {
  public constructor(email: string) {
    super(`No contributor found for user with email ${email}`)
  }
}

export type FisProjectMediaList = FisMediaList & {
  projectFisId: FisProjectId
}

export type CarbonSimulationForProject = CarbonSimulatorScenarioApiResponse & {
  projectBiomeId: BiomeProjectId
}
