import type { MainSpeciesDestinationViewModel } from '@/project/presentation/view-models/MainSpeciesDestination.viewmodel'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class WhatWillTheUsesOfWoodBeOnceCutPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<MainSpeciesDestinationViewModel> {
    const detailedProject = this.projectRepository.getProject()

    if (detailedProject.speciesDestination.length === 0) {
      return ViewModelContainer.WithError(new NoSpeciesDestinationAvailable())
    }
    return ViewModelContainer.WithContent({
      tags: detailedProject.speciesDestination,
    })
  }
}

class NoSpeciesDestinationAvailable extends Error {
  public constructor() {
    super('projectPage.technicalCharacteristics.mainSpeciesDestination.error.noDataAvailable')
  }
}
