export type ColorHexa = string & { _: 'hexa' }

export type ColorClass =
  | 'green'
  | 'green-dark'
  | 'green-light'
  | 'green-lighter'
  | 'blue'
  | 'red'
  | 'brown'
  | 'gold'
  | 'gold-light'
  | 'black'
  | 'grey'
  | 'grey-dark'
  | 'grey-darker'
  | 'grey-light'

type Constants = {
  colors: {
    greenLighter: ColorHexa
    greenLight: ColorHexa
    green: ColorHexa
    greenDark: ColorHexa
    blue: ColorHexa
    red: ColorHexa
    brown: ColorHexa
    gold: ColorHexa
    goldLight: ColorHexa
    grey: ColorHexa
    greyDark: ColorHexa
    greyDarker: ColorHexa
    greyLight: ColorHexa
  }
  colorsClasses: {
    greenLighter: ColorClass
    greenLight: ColorClass
    green: ColorClass
    greenDark: ColorClass
    blue: ColorClass
    red: ColorClass
    brown: ColorClass
    gold: ColorClass
    goldLight: ColorClass
    black: ColorClass
    grey: ColorClass
    greyDark: ColorClass
    greyDarker: ColorClass
    greyLight: ColorClass
  }
}

const computedStyle = getComputedStyle(document.documentElement)
const constants: Constants = {
  colors: {
    greenLighter: computedStyle.getPropertyValue('--green-lighter') as ColorHexa,
    greenLight: computedStyle.getPropertyValue('--green-light') as ColorHexa,
    green: computedStyle.getPropertyValue('--green') as ColorHexa,
    greenDark: computedStyle.getPropertyValue('--green-dark') as ColorHexa,
    blue: computedStyle.getPropertyValue('--blue') as ColorHexa,
    red: computedStyle.getPropertyValue('--red') as ColorHexa,
    brown: computedStyle.getPropertyValue('--brown') as ColorHexa,
    gold: computedStyle.getPropertyValue('--gold') as ColorHexa,
    goldLight: computedStyle.getPropertyValue('--gold-light') as ColorHexa,
    grey: computedStyle.getPropertyValue('--grey') as ColorHexa,
    greyLight: computedStyle.getPropertyValue('--grey-light') as ColorHexa,
    greyDarker: computedStyle.getPropertyValue('--grey-darker') as ColorHexa,
    greyDark: computedStyle.getPropertyValue('--grey-dark') as ColorHexa,
  },
  colorsClasses: {
    greenLighter: 'green-lighter',
    greenLight: 'green-light',
    green: 'green',
    greenDark: 'green-dark',
    blue: 'blue',
    red: 'red',
    brown: 'brown',
    gold: 'gold',
    goldLight: 'gold-light',
    black: 'black',
    grey: 'grey',
    greyLight: 'grey-light',
    greyDarker: 'grey-darker',
    greyDark: 'grey-dark',
  },
}

export default constants
