import AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'
import StorageService from '@/shared/infrastructure/services/Storage.service'
import BackofficeHttpgateway from '@/shared/infrastructure/gateways/Backoffice.httpgateway'
import BackofficeFakegateway from '@/shared/infrastructure/gateways/Backoffice.fakegateway'
import CarbonSimulatorFakeGateway from '@/shared/infrastructure/gateways/CarbonSimulator.fakegateway'
import CarbonSimulatorHttpGateway from '@/shared/infrastructure/gateways/CarbonSimulator.httpgateway'
import WhoAmIPresenter from '@/contributor/presentation/presenters/WhoAmIPresenter'
import WhatIsThisProjectSummaryPresenter from '@/project/presentation/presenters/WhatIsThisProjectSummaryPresenter'
import HowManyTreesInThisProjectPresenter from '@/project/presentation/presenters/HowManyTreesInThisProject.presenter'
import WhatAreaDoesThisProjectCoverPresenter from '@/project/presentation/presenters/WhatAreaDoesThisProjectCover.presenter'
import WhatIsThisProjectTypologiesPresenter from '@/project/presentation/presenters/WhatIsThisProjectTypologiesPresenter'
import WhatIsMyProjectStatusPresenter from '@/project/presentation/presenters/WhatIsMyProjectStatus.presenter'
import WhereIsLocatedMyProjectPresenter from '@/project/presentation/presenters/WhereIsLocatedMyProject.presenter'
import PolygonService from '@/project/infrastructure/services/PolygonService'
import WhichSpeciesArePresentInThisProjectPresenter from '@/project/presentation/presenters/WhichSpeciesArePresentInThisProject.presenter'
import WhatIsSpeciesDiversityInThisProjectPresenter from '@/project/presentation/presenters/WhatIsSpeciesDiversityInThisProject.presenter'
import WhatWillTheUsesOfWoodBeOnceCutPresenter from '@/project/presentation/presenters/WhatWillTheUsesOfWoodBeOnceCut.presenter'
import WhatIsMyProjectTimelinePresenter from '@/project/presentation/presenters/WhatIsMyProjectTimeline.presenter'
import CanISeeSomePhotosOfMyProjectPresenter from '@/project/presentation/presenters/CanISeeSomePhotosOfMyProject.presenter'
import HowManyCarbonISequesteredOverThirtyYearsPresenter from '@/project/presentation/presenters/HowManyCarbonISequesteredOverThirtyYears.presenter'
import env from '@/shared/env'
import ContributorHttpRepository from '@/contributor/infrastructure/repositories/Contributor.httprepository'
import { DetailedProjectHttpRepository } from '@/project/infrastructure/repositories/DetailedProject.httprepository'
import { BiodiversitySimulatorHttpGateway } from '@/shared/infrastructure/gateways/BiodiversitySimulator.httpgateway'
import WhereICanSeeMyAnnualReportPresenter from '@/contributor/presentation/presenters/WhereICanSeeMyAnnualReport.presenter'
import WhatIsMyBiodiversityImpactOverThirtyYearsPresenter from '@/project/presentation/presenters/WhatIsMyBiodiversityImpactOverThirtyYears.presenter'
import { BiodiversitySimulatorFakeGateway } from '@/shared/infrastructure/gateways/BiodiversitySimulator.fakegateway'
import CanIHaveTheProjectInExcelFormatPresenter from '@/project/presentation/presenters/CanIHaveTheProjectInExcelFormat.presenter'
import RemoteSensingHttpgateway from '@/project/infrastructure/gateways/RemoteSensing.httpgateway'
import BackendHttpGateway from '@/project/infrastructure/gateways/Backend.httpgateway'
import BackendFakeGateway from '@/project/infrastructure/gateways/Backend.fakegateway'
import CanISeeMyRemoteSensingIndexesPresenter from '@/project/presentation/presenters/CanISeeMyRemoteSensingIndexes.presenter'
import CanISeeMyRemoteSensingSpectralSpeciesPresenter from '@/project/presentation/presenters/CanISeeMyRemoteSensingSpectralSpecies.presenter'

const storageService = new StorageService()
const backofficeGateway = env.isMock
  ? new BackofficeFakegateway()
  : new BackofficeHttpgateway(env.backoffice.url)

const authenticationService = new AuthenticationService(storageService, backofficeGateway)

const backendGateway = env.isMock
  ? new BackendFakeGateway()
  : new BackendHttpGateway(env.backend.url, authenticationService)

const carbonSimulatorGateway = env.isMock
  ? new CarbonSimulatorFakeGateway()
  : new CarbonSimulatorHttpGateway(env.carbonSimulator.url)

const biodiversitySimulatorGateway = env.isMock
  ? new BiodiversitySimulatorFakeGateway()
  : new BiodiversitySimulatorHttpGateway(env.backend.url, authenticationService)

const polygonService = new PolygonService(backofficeGateway, storageService)

const remoteSensingGateway = new RemoteSensingHttpgateway(env.remoteSensing.url)

const contributorRepository = new ContributorHttpRepository(
  storageService,
  authenticationService,
  backofficeGateway,
  carbonSimulatorGateway,
  biodiversitySimulatorGateway,
)

const projectRepository = new DetailedProjectHttpRepository(
  storageService,
  backofficeGateway,
  authenticationService,
  polygonService,
  carbonSimulatorGateway,
  biodiversitySimulatorGateway,
  remoteSensingGateway,
  backendGateway,
)

export const container = {
  storageService,
  contributorRepository,
  projectRepository,
  authenticationService,
  whoAmIPresenter: new WhoAmIPresenter(contributorRepository),
  whatIsThisProjectSummaryPresenter: new WhatIsThisProjectSummaryPresenter(projectRepository),
  howManyTreesInThisProjectPresenter: new HowManyTreesInThisProjectPresenter(projectRepository),
  whatAreaDoesThisProjectCoverPresenter: new WhatAreaDoesThisProjectCoverPresenter(
    projectRepository,
  ),
  whatIsThisProjectTypologiesPresenter: new WhatIsThisProjectTypologiesPresenter(projectRepository),
  whatIsMyProjectStatusPresenter: new WhatIsMyProjectStatusPresenter(projectRepository),
  whereIsLocatedMyProjectPresenter: new WhereIsLocatedMyProjectPresenter(projectRepository),
  whichSpeciesArePresentInThisProjectPresenter: new WhichSpeciesArePresentInThisProjectPresenter(
    projectRepository,
  ),
  whatIsSpeciesDiversityInThisProjectPresenter: new WhatIsSpeciesDiversityInThisProjectPresenter(
    projectRepository,
  ),
  whatWillTheUsesOfWoodBeOnceCutPresenter: new WhatWillTheUsesOfWoodBeOnceCutPresenter(
    projectRepository,
  ),
  whatIsMyProjectTimelinePresenter: new WhatIsMyProjectTimelinePresenter(projectRepository),
  canISeeSomePhotosOfMyProjectPresenter: new CanISeeSomePhotosOfMyProjectPresenter(
    backofficeGateway,
    authenticationService,
  ),
  howManyCarbonISequesteredOverThirtyYearsPresenter:
    new HowManyCarbonISequesteredOverThirtyYearsPresenter(projectRepository),
  whereICanSeeMyAnnualReportPresenter: new WhereICanSeeMyAnnualReportPresenter(storageService),
  whatIsMyBiodiversityImpactOverThirtyYearsPresenter:
    new WhatIsMyBiodiversityImpactOverThirtyYearsPresenter(projectRepository),
  canIHaveTheProjectInExcelFormatPresenter: new CanIHaveTheProjectInExcelFormatPresenter(
    projectRepository,
  ),
  canISeeMyRemoteSensingPresenter: new CanISeeMyRemoteSensingIndexesPresenter(projectRepository),
  canISeeMyRemoteSensingSpectralSpeciesPresenter:
    new CanISeeMyRemoteSensingSpectralSpeciesPresenter(projectRepository),
}
